<template>
  <div class="page_qs">
    <div class="qs_info">
      <div class="introduce_top">
        <div class="title">
          <div class="line"></div>

          <div class="txt">轻食模块</div>

          <div class="line"></div>
        </div>

        <div class="sub_title">轻食品类创新解决方案</div>
      </div>

      <div class="introduce_info">
        <div class="introduce_top_left">
          <div class="introduce_left_content">
            <div>美食创新、精致、文化，成就轻食产业更高的市场价值和利润。</div>
            <div
              class="span-space"
            >食的作品致力于打造美味、精致、小吃文化、操作简单的轻食主义和轻食文化，使饮食更轻盈、更健康、更时尚感，深受“新生代”消费群体欢迎的饮食选择；形成以轻食文化融入和改变“新生代”生活习惯，契合“新生代”饮食时尚、精致、健康的饮食观。</div>
          </div>
        </div>
        <div class="introduce_top_right">
          <img class="introduce_right_img" src="../assets/food_introduce.png" />
        </div>
      </div>

      <div class="introduce_bottom">
        <div class="introduce_bottom_content">
          <div>
            “食的作品”是食的作品 (杭州)
            餐饮管理有限公司旗下的轻食板块品牌，品牌下的产品以多种轻式美食小吃和爆款饮品为主，以年轻消费群体为需求导向而研发，以品牌连锁效应为市场运营，是受欢迎程度较大的行业和产业项目。
          </div>
          <div class="span-space">一经落地，广受消费者热捧，成为市场新宠。</div>
        </div>
      </div>

      <!--- 项目产品 -->
      <div class="page_project">
        <div class="swiper">
          <swiper
            :options="swiperOption"
            ref="mySwiper"
            @slideChangeTransitionEnd="handleClickSlide"
            @click="show"
          >
            <swiper-slide v-for="item of foodList" :key="item.imgStr">
              <!-- <viewer> -->
              <img class="food_img" :src="item.imgStr" />
              <!-- </viewer> -->
            </swiper-slide>
          </swiper>
        </div>
      </div>

      <!--- 门店加盟 -->

      <div class="page_join">
        <div class="introduce_top">
          <div class="title">
            <div class="line"></div>

            <div class="txt">门店加盟</div>

            <div class="line"></div>
          </div>

          <div class="sub_title">轻食门店设计解决方案</div>
        </div>

        <div class="store_img">
          <img src="../assets/home/home_mendian_bg.png"/>
        </div>

        <div class="bottom">
          <div class="txt1">美食创新、精致、文化，成就轻食产业更高的市场价值和利润。</div>
          <div
            class="txt2"
          >食的作品致力于打造美味、精致、小吃文化、操作简单的轻食主义和轻食文化，使饮食更轻盈、更健康、更时尚感，深受“新生代”消费群体欢迎的饮食选择；形成以轻食文化融入和改变“新生代”生活习惯，契合“新生代”饮食时尚、精致、健康的饮食观。</div>
        </div>
      </div>

      <!--- 线下案例 -->
      <div class="page_real">
        <div class="introduce_top">
          <div class="title">
            <div class="line"></div>

            <div class="txt">线下案例</div>

            <div class="line"></div>
          </div>

          <div class="sub_title">真实门店展示</div>
        
        </div>

        <div class="swiper-box">
          <Swiperit></Swiperit>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import { SwiperSlide, Swiper } from "vue-awesome-swiper";
import Swiperit from "@/components/customSwiper.vue";
import "swiper/css/swiper.css";
export default {
  components: {
    Swiper,
    SwiperSlide,
    Swiperit
  },
  data() {
    return {
      foodList: [
        {
          title: "港式蛋多多爆蛋吐司",
          subTitle: "More pop egg toast",
          imgStr: require("../assets/food/food_ts_moreEgg.jpg")
        },
        {
          title: "烤猪排芝士蛋爆蛋吐司",
          subTitle: "Pork chop cheese ",
          imgStr: require("../assets/food/food_ts_zpzsd.jpg")
        },
        {
          title: "沙拉肥牛煎饼",
          subTitle: "Salad beef",
          imgStr: require("../assets/food/food_jb_slfn.jpg")
        },
        {
          title: "太阳蛋嫩香肥牛爆蛋吐司",
          subTitle: "Fried egg toast",
          imgStr: require("../assets/food/food_ts_sunEgg.jpg")
        },

        {
          title: "芝士培根爆蛋吐司",
          subTitle: "Cheese bacon",
          imgStr: require("../assets/food/food_ts_zspg.jpg")
        },
        {
          title: "沙拉鸡排煎饼",
          subTitle: "Chicken chop pancakes with salad",
          imgStr: require("../assets/food/food_jb_sljp.jpg")
        },
        {
          title: "芝士鸡排爆蛋吐司",
          subTitle: "Cheese chicken ribs",
          imgStr: require("../assets/food/food_ts_zsjp.jpg")
        },
        {
          title: "芝士火腿爆蛋吐司",
          subTitle: "Cheese ham",
          imgStr: require("../assets/food/food_ts_zsht.jpg")
        },
        {
          title: "爆汁烤肠煎饼",
          subTitle: "Grilled sausage",
          imgStr: require("../assets/food/food_jb_bzkc.jpg")
        },
        {
          title: "私家巴沙鱼爆蛋吐司",
          subTitle: "Private eel",
          imgStr: require("../assets/food/food_ts_bsy.jpg")
        },

        {
          title: "首尔炸鸡",
          subTitle: "Seoul fried chicken",
          imgStr: require("../assets/food/food_xc_srzj.jpg")
        },
        {
          title: "爆蛋吐司+小吃+咖啡/饮料自选",
          subTitle: "Fried egg toast + Snack + Coffee/drink of your choice",
          imgStr: require("../assets/food/food_tc_tsCoffeXc.jpg")
        },
        {
          title: "香辣鸡翅",
          subTitle: "Seoul fried chicken",
          imgStr: require("../assets/food/food_xc_xljc.jpg")
        },
        {
          title: "爆款DIY爆蛋吐司",
          subTitle: "DIY fried egg toast",
          imgStr: require("../assets/food/food_diyCoffe.jpg")
        }
      ],
      swiperOption: {
        direction: "horizontal", // 滑动的方向(水平方向)
        loop: true, // 是否循环
        autoplay: {
          delay: 4000 // 自动播放的时长
        },
        clickable: true, //允许分页点击跳转
        slidesPerView: 5, // 设置可视区一共显示几张图片,设置auto,设置auto为自适应;
        centeredSlides: true, // 设置为true时,带有active-slide类名的图片会居中
        spaceBetween: 2 //  每张图片之间的间隔
      }
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
  watch: {}
};
</script>
<style scoped lang="less">
.page_qs {
  width: 100%;
  .qs_info {
    width: 80%;
    margin: auto;
    margin-top: 82px;

    .introduce_info {
      display: flex;
      margin-top: 156px;
      .introduce_top_left {
        width: 50%;

        .introduce_left_content {
          width: 100%;
          // width: 510px;
          height: 156px;
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          text-align: left;
          padding-top: 98px;
          .span-space {
            margin-top: 40px;
          }
        }
      }
      .introduce_top_right {
        width: 50%;
        .introduce_right_img {
          // padding-left: 200px;
          width: 510px;
          height: 307px;
        }
      }
    }
    .introduce_bottom {
      margin-top: 103px;
      text-align: left;
      .introduce_bottom_content {
        margin-top: 58px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        .span-space {
          margin-top: 40px;
        }
      }
    }

    .page_project {
      margin-top: 98px;

      .swiper {
        width: 100%;
        margin: auto;
        .swiper-slide {
          text-align: center;
          font-size: 15px;
          background: #fff;
          height: 435px;
          /* Center slide text vertically */
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;

          align-items: center;
          transition: 300ms;
          transform: scale(0.6);

          box-sizing: border-box;
          border: 4px solid #61baba;
          border-radius: 21px;
        }
        // .swiper-slide-active,
        // .swiper-slide-duplicate-active {
        //   transform: scale(1);
        //   border: 4px solid #f6b231;
        //   border-radius: 21px;
        // }
        .food_img {
          width: 100%;
          height: 100%;
          margin: auto;
          border-radius: 17px;
        }
      }
    }

    .page_join {
      margin-top: 180px;
      .store_img {
        margin-top: 156px;
        width: 100%;
        height: 686px;
       
        img{
          width: 100%;
          height: 100%;
        }
      }

      .bottom {
        margin-top: 60px;
        text-align: left;
        .txt1 {
          font-size: 16px;

          font-weight: 500;
          color: #333333;
        }
        .txt2 {
          margin-top: 30px;
          font-size: 16px;
          font-weight: 500;
          color: #333333;
        }
      }
    }

    .page_real {
      margin-top: 158px;
      margin-bottom: 387px;
      .swiper-box {
        margin-top: 139px;
        width: 100%;
        height: 347px;
        margin: auto;
        height: 347rpx;
      }
    }
  }

  .introduce_top {
    .title {
      display: flex;
      align-items: center;
      justify-content: center;

      .txt {
        // width: 142px;
        height: 31px;
        line-height: 31px;
        font-size: 32px;
        padding-left: 15px;
        padding-right: 15px;
        font-weight: bold;
        color: #333333;
      }

      .line {
        width: 48px;
        height: 2px;
        background-color: #333333;
      }
    }
    .sub_title {
      margin-top: 24px;
      font-size: 16px;
      font-weight: 400;
      color: #999;
    }
  }
}
</style>
    